var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('section',{attrs:{"id":"ProjBanksy"}},[_c('div',{staticClass:"container"},[_c('page-title',{attrs:{"title":"Banksy Exhibition","subtitle":"Il progetto tratta un <u>bundle in edizione limitata</u> realizzato per un'ipotetica mostra di Banksy. Secondo la ”filosofia” di Banksy, questo non sarebbe il luogo adatto per osservare le sue opere e percepirne appieno il loro significato; per questo ho cercato di creare una serie di oggetti che potesse trasmettere nel modo migliore il ”cuore” delle opere. Il bundle è composto da una <u>shopper personalizzabile</u> tramite stencil (inseriti all'interno), un <u>artbook</u> contenente alcune sue celebri opere, una <u>cartolina</u> e una <u>bomboletta spray</u> (da utilizzare per personalizzare la shopper)."}}),_vm._m(0),_c('Showreel',{attrs:{"title":"La Shopper","imgs":[
                {
                    find: 'proj-banksy/shop1.jpeg',
                    imgType: 'cover',
                    alt: '', // <-- image alt text
                    text: ''
                },
                {
                    find: 'proj-banksy/shop2.jpeg',
                    imgType: 'cover',
                    alt: '', // <-- image alt text
                    text: ''
                },
                {
                    find: 'proj-banksy/shop3.jpeg',
                    imgType: 'cover',
                    alt: '', // <-- image alt text
                    text: ''
                },
                {
                    find: 'proj-banksy/shop4.jpeg',
                    imgType: 'cover',
                    alt: '', // <-- image alt text
                    text: ''
                },
                {
                    find: 'proj-banksy/shop5.jpeg',
                    imgType: 'cover',
                    alt: '', // <-- image alt text
                    text: ''
                } ]}}),_vm._m(1),_c('Showreel',{attrs:{"title":"L'artbook","imgs":[
                {
                    find: 'proj-banksy/book0.jpeg',
                    imgType: 'cover',
                    alt: '', // <-- image alt text
                    text: ''
                },
                {
                    find: 'proj-banksy/book1.jpeg',
                    imgType: 'cover',
                    alt: '', // <-- image alt text
                    text: ''
                },
                {
                    find: 'proj-banksy/book2.jpeg',
                    imgType: 'cover',
                    alt: '', // <-- image alt text
                    text: ''
                },
                {
                    find: 'proj-banksy/book3.jpeg',
                    imgType: 'cover',
                    alt: '', // <-- image alt text
                    text: ''
                },
                {
                    find: 'proj-banksy/book4.jpeg',
                    imgType: 'cover',
                    alt: '', // <-- image alt text
                    text: ''
                } ]}}),_c('ReadThe',{attrs:{"title":"Sfoglia l'artbook","website":false,"webUrl":"","magazine":true,"pages":_vm.pages /** Send an Array of pages images (all at same resolution)*/,"startPage":null /** Insert start-page must be 'null' or number >= 1 */,"singlePage":false /** single-page format */,"haveCover":true /** Set true if your book have a cover */}})],1)])}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"alone"},[_c('img',{attrs:{"src":require("@/assets/imgs/proj-banksy/all.jpeg"),"alt":""}})])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"alone"},[_c('img',{attrs:{"src":require("@/assets/imgs/proj-banksy/phones.png"),"alt":""}})])}]

export { render, staticRenderFns }