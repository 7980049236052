<template>
    <section id="ProjBanksy">
        

        <div class="container">
        
        <!-- PAGE TITLE -->
            <page-title 
                title="Banksy Exhibition" 
                subtitle="Il progetto tratta un <u>bundle in edizione limitata</u> realizzato per un'ipotetica mostra di Banksy. Secondo la ”filosofia” di Banksy, questo non sarebbe il luogo adatto per osservare le sue opere e percepirne appieno il loro significato; per questo ho cercato di creare una serie di oggetti che potesse trasmettere nel modo migliore il ”cuore” delle opere. Il bundle è composto da una <u>shopper personalizzabile</u> tramite stencil (inseriti all'interno), un <u>artbook</u> contenente alcune sue celebri opere, una <u>cartolina</u> e una <u>bomboletta spray</u> (da utilizzare per personalizzare la shopper)."
            />
        <!-- --------------------- -->

        <div class="alone">
            <img src="@/assets/imgs/proj-banksy/all.jpeg" alt="">
        </div>

        <!-- SHOPPING BAG SHOWREEL -->
            <Showreel
                title="La Shopper"
                :imgs="[
                    {
                        find: 'proj-banksy/shop1.jpeg',
                        imgType: 'cover',
                        alt: '', // <-- image alt text
                        text: ''
                    },
                    {
                        find: 'proj-banksy/shop2.jpeg',
                        imgType: 'cover',
                        alt: '', // <-- image alt text
                        text: ''
                    },
                    {
                        find: 'proj-banksy/shop3.jpeg',
                        imgType: 'cover',
                        alt: '', // <-- image alt text
                        text: ''
                    },
                    {
                        find: 'proj-banksy/shop4.jpeg',
                        imgType: 'cover',
                        alt: '', // <-- image alt text
                        text: ''
                    },
                    {
                        find: 'proj-banksy/shop5.jpeg',
                        imgType: 'cover',
                        alt: '', // <-- image alt text
                        text: ''
                    },
                ]"
            />
        <!-- --------------------- -->

        <div class="alone">
            <img src="@/assets/imgs/proj-banksy/phones.png" alt="">
        </div>

        <!-- BOOK SHOWREEL -->
            <Showreel
                title="L'artbook"
                :imgs="[
                    {
                        find: 'proj-banksy/book0.jpeg',
                        imgType: 'cover',
                        alt: '', // <-- image alt text
                        text: ''
                    },
                    {
                        find: 'proj-banksy/book1.jpeg',
                        imgType: 'cover',
                        alt: '', // <-- image alt text
                        text: ''
                    },
                    {
                        find: 'proj-banksy/book2.jpeg',
                        imgType: 'cover',
                        alt: '', // <-- image alt text
                        text: ''
                    },
                    {
                        find: 'proj-banksy/book3.jpeg',
                        imgType: 'cover',
                        alt: '', // <-- image alt text
                        text: ''
                    },
                    {
                        find: 'proj-banksy/book4.jpeg',
                        imgType: 'cover',
                        alt: '', // <-- image alt text
                        text: ''
                    },
                ]"
            />
        <!-- --------------------- -->

        <!-- READ THE WEBSITE OR MAGAZINE -->
            <ReadThe
                title="Sfoglia l'artbook"
                :website="false"
                webUrl=""

                :magazine="true"
                :pages="pages /** Send an Array of pages images (all at same resolution)*/" 
                :startPage="null /** Insert start-page must be 'null' or number >= 1 */"
                :singlePage="false /** single-page format */"
                :haveCover="true /** Set true if your book have a cover */"
            />
        <!-- ---------------------------- -->

        </div>
    </section>
</template>

<script>
import PageTitle from '../../components/small/PageTitle.vue'
import ReadThe from '../../components/works-project/ReadThe.vue'
import Showreel from '../../components/works-project/Showreel.vue'

export default {
    name: 'ProjBanksy',
    components: {
        PageTitle,
        Showreel,
        ReadThe
    },
    metaInfo: {
        title: 'Banksy',
        meta: [{
            vmid: 'description',
            name: 'description',
            content: 'Pacchetto in edizione limitata per un\'ipotetica mostra di Banksy: Shopper personalizzabile tramite stencil inseriti all\'interno, contiene anche un catalogo con alcune delle sue opere più famose, una cartolina e una bomboletta spray.',
        }],
    },
    data(){
        return{
            pages: [ //use the link to imgs -- put the files in src/book/folder and will be see when website is uploaded
                "https://arisgoi.it/books/book-banksy/copertinaF.jpg",
                "https://arisgoi.it/books/book-banksy/white.jpg",
                "https://arisgoi.it/books/book-banksy/book.jpg",
                "https://arisgoi.it/books/book-banksy/book2.jpg",
                "https://arisgoi.it/books/book-banksy/book3.jpg",
                "https://arisgoi.it/books/book-banksy/book4.jpg",
                "https://arisgoi.it/books/book-banksy/book5.jpg",
                "https://arisgoi.it/books/book-banksy/book6.jpg",
                "https://arisgoi.it/books/book-banksy/book7.jpg",
                "https://arisgoi.it/books/book-banksy/book8.jpg",
                "https://arisgoi.it/books/book-banksy/book9.jpg",
                "https://arisgoi.it/books/book-banksy/book10.jpg",
                "https://arisgoi.it/books/book-banksy/book11.jpg",
                "https://arisgoi.it/books/book-banksy/book12.jpg",
                "https://arisgoi.it/books/book-banksy/book13.jpg",
                "https://arisgoi.it/books/book-banksy/book14.jpg",
                "https://arisgoi.it/books/book-banksy/book15.jpg",
                "https://arisgoi.it/books/book-banksy/book16.jpg",
                "https://arisgoi.it/books/book-banksy/book17.jpg",
                "https://arisgoi.it/books/book-banksy/book18.jpg",
                "https://arisgoi.it/books/book-banksy/book19.jpg",
                "https://arisgoi.it/books/book-banksy/book20.jpg",
                "https://arisgoi.it/books/book-banksy/book21.jpg",
                "https://arisgoi.it/books/book-banksy/book22.jpg",
                "https://arisgoi.it/books/book-banksy/book23.jpg",
                "https://arisgoi.it/books/book-banksy/book24.jpg",
                "https://arisgoi.it/books/book-banksy/book25.jpg",
                "https://arisgoi.it/books/book-banksy/book26.jpg",
                "https://arisgoi.it/books/book-banksy/book27.jpg",
                "https://arisgoi.it/books/book-banksy/book28.jpg",
                "https://arisgoi.it/books/book-banksy/book29.jpg",
                "https://arisgoi.it/books/book-banksy/book30.jpg",
                "https://arisgoi.it/books/book-banksy/book31.jpg",
                "https://arisgoi.it/books/book-banksy/book32.jpg",
                "https://arisgoi.it/books/book-banksy/book33.jpg",
                "https://arisgoi.it/books/book-banksy/book34.jpg",
                "https://arisgoi.it/books/book-banksy/book35.jpg",
                "https://arisgoi.it/books/book-banksy/book36.jpg",
                "https://arisgoi.it/books/book-banksy/book37.jpg",
                "https://arisgoi.it/books/book-banksy/book38.jpg",
                "https://arisgoi.it/books/book-banksy/book39.jpg",
                "https://arisgoi.it/books/book-banksy/book40.jpg",
                "https://arisgoi.it/books/book-banksy/book41.jpg",
                "https://arisgoi.it/books/book-banksy/book42.jpg",
                "https://arisgoi.it/books/book-banksy/book43.jpg",
                "https://arisgoi.it/books/book-banksy/book44.jpg",
                "https://arisgoi.it/books/book-banksy/book45.jpg",
                "https://arisgoi.it/books/book-banksy/book46.jpg",
                "https://arisgoi.it/books/book-banksy/book47.jpg",
                "https://arisgoi.it/books/book-banksy/book48.jpg",
                "https://arisgoi.it/books/book-banksy/book49.jpg",
                "https://arisgoi.it/books/book-banksy/book50.jpg",
                "https://arisgoi.it/books/book-banksy/book51.jpg",
                "https://arisgoi.it/books/book-banksy/book52.jpg",
                "https://arisgoi.it/books/book-banksy/book53.jpg",
                "https://arisgoi.it/books/book-banksy/book54.jpg",
                "https://arisgoi.it/books/book-banksy/book55.jpg",
                "https://arisgoi.it/books/book-banksy/book56.jpg",
                "https://arisgoi.it/books/book-banksy/book57.jpg",
                "https://arisgoi.it/books/book-banksy/book58.jpg",
                "https://arisgoi.it/books/book-banksy/book59.jpg",
                "https://arisgoi.it/books/book-banksy/book60.jpg",
                "https://arisgoi.it/books/book-banksy/book61.jpg",
                "https://arisgoi.it/books/book-banksy/book62.jpg",
                "https://arisgoi.it/books/book-banksy/book63.jpg",
                "https://arisgoi.it/books/book-banksy/book64.jpg",
                "https://arisgoi.it/books/book-banksy/book65.jpg",
                "https://arisgoi.it/books/book-banksy/book66.jpg",
                "https://arisgoi.it/books/book-banksy/book67.jpg",
                "https://arisgoi.it/books/book-banksy/book68.jpg",
                "https://arisgoi.it/books/book-banksy/book69.jpg",
                "https://arisgoi.it/books/book-banksy/book70.jpg",
                "https://arisgoi.it/books/book-banksy/book71.jpg",
                "https://arisgoi.it/books/book-banksy/book72.jpg",
                "https://arisgoi.it/books/book-banksy/book73.jpg",
                "https://arisgoi.it/books/book-banksy/book74.jpg",
                "https://arisgoi.it/books/book-banksy/book75.jpg",
                "https://arisgoi.it/books/book-banksy/book76.jpg",
                "https://arisgoi.it/books/book-banksy/white.jpg",
                "https://arisgoi.it/books/book-banksy/copertinaB.jpg",
            ]
        }
    },
    methods: {
        //
    }
}
</script>

<style scoped lang="scss">
@import '@/scss/_variables.scss';

</style>